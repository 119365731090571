@import "https://api.ayabrackett.com/assets/fonts.css";
:root {
  --category-font: "GT America Bold", sans-serif;
  --body-font: "GT America Mono", monospace;
  --ui-font: "GT America", sans-serif;
  --dark: #323844;
  --blue: blue;
  --light-gray: #eee;
  --row-height: 87px;
  --shadow: 0 1px 1px #00000005, 0 3px 3px #00000005, 0 6px 5px #00000008, 0 11px 9px #00000008, 0 20px 16px #00000008, 0 40px 30px #0000000a;
  --shadow-before: 0 2px 2px #0000, 0 6px 5px #0000, 0 12px 10px #0000, 0 22px 18px #0000, 0 40px 33px #0000, 0 80px 60px #0000;
}

::selection {
  background: var(--blue);
}

#App {
  width: 100%;
}

#left-nav {
  grid-area: nav;
}

nav a, .projects a {
  color: inherit;
}

nav .projects {
  margin: 0;
  padding: 0;
  list-style: none;
}

.projects {
  font-family: var(--category-font), sans-serif;
  color: var(--dark);
  -webkit-text-fill-color: #fff0;
  -webkit-text-stroke-width: .66px;
  -webkit-text-stroke-color: var(--dark);
  margin: 6px 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 110%;
  text-decoration: none;
}

.projects .category-link-selected, .projects .category-link:hover {
  color: var(--dark);
  -webkit-text-fill-color: var(--dark);
  transition: color .25s ease-in-out;
}

.nav-wrapper {
  position: sticky;
  top: 64px;
}

.links {
  margin: 24px 0 0;
  padding: 0;
  line-height: 140%;
  list-style: none;
}

.links a {
  color: var(--dark);
  transition: all .3s ease-in-out;
}

.links :hover {
  opacity: .8;
  transition: all .3s ease-in-out;
}

.links ul {
  margin: 0;
  padding: 0;
}

.links li {
  font-family: var(--body-font), sans-serif;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: var(--dark);
  margin: 6px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
}

li > span:hover {
  cursor: pointer;
}

.selected-link:after {
  content: "•";
}

@media only screen and (width <= 760px) {
  .links {
    font-size: 16px;
  }
}

@media only screen and (width <= 560px) {
  .logo {
    align-items: flex-start;
  }

  nav, .main > .gallery-toggle {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

#App {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template: "header header" 160px
                 "nav content"
                 "blank blank"
                 / 1fr 3fr;
  margin: 0;
  padding: 0 80px;
  display: grid;
}

.content {
  flex-wrap: wrap;
  grid-area: content;
  align-content: flex-start;
  max-width: 100%;
  height: 100%;
  display: flex;
}

html {
  font-family: var(--body-font), monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 150%;
}

h1 {
  font-family: var(--ui-font), sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 120%;
}

em {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-style: normal;
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

@media only screen and (width <= 560px) {
  .content img {
    border-radius: 0;
  }
}

a {
  cursor: pointer;
  color: var(--blue);
  text-decoration: none;
}

.horizontal-center {
  align-items: center;
  display: flex;
}

.page-container {
  text-align: center;
  justify-content: center;
  width: 100%;
  display: flex;
}

.main {
  grid-area: content;
}

@media only screen and (width <= 560px) {
  .main {
    position: relative;
    top: 87px;
  }
}

@media only screen and (width <= 960px) {
  #App {
    grid-template-columns: 1fr 1.6fr;
    padding: 0 56px;
  }
}

@media only screen and (width <= 760px) {
  #App {
    padding: 0 32px;
  }
}

@media only screen and (width <= 560px) {
  #App {
    grid-template-columns: 1fr;
    grid-template-rows: var(--row-height) auto var(--row-height);
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-areas: "header"
                         "content"
                         "nav";
    padding: 0;
  }
}

.gallery-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.gallery {
  width: 100%;
  height: 100%;
}

.image-container {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.image-container svg {
  transform: rotate(90deg);
}

.image-wrapper {
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  display: flex;
}

.single-pic {
  object-fit: contain;
  -ms-animation: fadein .12s;
  max-width: 100%;
  max-height: calc(100% - 32px);
  animation: .12s fadein;
}

@media only screen and (width <= 560px) {
  .single-pic {
    object-fit: contain;
    max-width: 100%;
    height: calc(100% - 48px);
  }

  .right-overlay, .left-overlay {
    height: 100%;
  }

  .image-wrapper {
    justify-content: start;
    width: auto;
  }
}

.fade-out {
  -ms-animation: fadeout .2s;
  animation: .2s fadeout;
}

.image-info {
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  display: flex;
}

.image-info .caption-nav {
  font-family: var(--ui-font), sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  min-width: 56px;
  font-size: 8px;
  line-height: 10px;
}

.image-info .index-arrow {
  vertical-align: -2px;
  width: auto;
  min-width: 0;
  height: 8px;
}

.index-arrow:hover {
  cursor: pointer;
}

.left-overlay, .right-overlay {
  opacity: 0;
  z-index: 2;
  width: 50%;
  height: calc(100% - 56px);
  padding: 48px 0;
  display: block;
  position: absolute;
  top: 0;
}

.no-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.left-overlay > div, .right-overlay > div {
  width: 100%;
  height: 100%;
}

.left-overlay {
  left: 0;
}

.right-overlay {
  right: 0;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media only screen and (width <= 560px) {
  .caption {
    font-size: .82em;
  }

  .image-info {
    padding: 0 8.33%;
    display: none;
  }
}

.about-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-area: content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "profile about about"
                       "contact press press"
                       "clients clients clients";
  margin-bottom: 128px;
  display: grid;
}

.about-container ul li {
  margin-bottom: 4px;
}

.about-container img {
  border-radius: 4px;
}

.profile-pic {
  grid-area: profile;
  width: 62%;
}

.contact-info {
  grid-area: contact;
}

.bio {
  grid-area: about;
}

.bio h1 {
  margin-top: 0;
}

.press {
  grid-area: press;
}

.press ul {
  padding-left: 1em;
  list-style-type: circle;
}

.clients {
  grid-area: clients;
}

.client-list {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

@media only screen and (width <= 960px) {
  .about-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "profile contact contact"
                         "about about about"
                         "press press press"
                         "clients clients clients";
  }

  .profile-pic {
    width: 80%;
    margin-bottom: 64px;
  }

  .client-list {
    grid-template-columns: 1fr 1fr;
  }

  .client-list ul {
    margin-bottom: 24px;
    padding-right: 24px;
  }
}

@media only screen and (width <= 560px) {
  .about-container {
    padding: 0 24px;
  }
}

.grid-image {
  width: 33.33%;
  height: auto;
}

.grid-image.book-image:nth-of-type(2) {
  width: 66.67%;
}

.grid-image img {
  background-color: var(--light-gray);
  object-fit: cover;
  width: 100%;
  height: 80%;
}

.gallery-image img {
  height: 100%;
}

.gallery-content {
  flex-wrap: wrap;
  grid-area: content;
  align-content: flex-start;
  max-width: 100%;
  height: 100%;
  display: flex;
}

figure {
  margin: 0;
  padding: 8px;
}

figure img {
  -ms-animation: fadein 1s;
  box-shadow: var(--shadow-before);
  mix-blend-mode: multiply;
  border-radius: 4px;
  transition: all .6s cubic-bezier(.165, .84, .44, 1);
  animation: 1s fadein;
}

figure img:after {
  box-shadow: var(--shadow);
  transition: all .6s cubic-bezier(.165, .84, .44, 1);
}

.grid-image img:hover {
  cursor: pointer;
  box-shadow: var(--shadow);
  transform: translateY(-1px);
}

.post-images img:hover {
  cursor: default;
  box-shadow: var(--shadow-before);
  transform: translateY(0);
}

.post-images .post-image__clickable:hover {
  cursor: pointer;
}

figcaption, .caption {
  font-family: var(--body-font), monospace;
  text-transform: uppercase;
  letter-spacing: .03em;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  font-size: 12px;
  display: flex;
}

figcaption h1 {
  width: 100%;
  margin: 4px 0;
}

.image-spacer {
  width: calc(100% - 4px);
  display: inline-block;
}

@media only screen and (width <= 960px) {
  .grid-image {
    width: 44%;
    min-height: auto;
  }
}

@media only screen and (width <= 560px) {
  .grid-image {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    width: 33.33%;
    margin: 0;
    padding: 0;
  }

  .grid-image:nth-of-type(3n) {
    border-right: none;
  }

  .content {
    padding-bottom: 92px;
  }

  .image-wrapper {
    padding: 0;
  }

  .grid-image:nth-of-type(3n) {
    width: 33.33%;
  }

  .grid-image img {
    border-radius: 0;
    height: 100%;
  }

  .category-image {
    flex-direction: column;
    display: flex;
    position: relative;
  }

  .category-image img {
    height: calc(100% - 32px);
  }

  figcaption {
    background: var(--light-gray);
    align-content: center;
    width: 100%;
    height: 32px;
    padding: 0 8px;
    font-size: 10px;
    line-height: 110%;
    display: flex;
  }

  .product figcaption {
    display: block;
  }
}

.search {
  font-family: var(--ui-font), sans-serif;
  letter-spacing: .03em;
  border: none;
  font-size: 12px;
}

.main .search {
  text-align: right;
}

.search:focus {
  outline: 0;
}

.search-container {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.search-container img:hover {
  cursor: pointer;
}

.search-container button {
  background: none;
  border: none;
  outline: none;
}

.search-container button img {
  margin-top: 2px;
}

.search-wrapper {
  border: 1px solid var(--light-gray);
  border-radius: 32px;
  justify-content: flex-end;
  align-items: center;
  width: 256px;
  padding: 8px 12px;
  display: flex;
}

.search-wrapper input {
  width: 100%;
}

.search-wrapper input:hover {
  cursor: text;
}

.search-wrapper button:hover {
  cursor: pointer;
}

@media only screen and (width <= 720px) {
  .search-wrapper {
    width: 176px;
  }
}

@media only screen and (width <= 560px) {
  .search-wrapper {
    width: 100%;
  }
}

.search-component {
  align-items: flex-end;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 560px) {
  .search-container {
    display: none;
  }

  .hamburger-menu .search-container {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
  }

  .hamburger-menu .search {
    width: 100%;
    height: 100%;
    padding: 0 12px;
  }

  .hamburger-menu .search::placeholder {
    letter-spacing: .15em;
    font-size: 8px;
  }
}

#navbar {
  border-top: 1px solid var(--dark);
  background: #fff;
  padding: 24px 32px;
  display: none;
}

@media only screen and (width <= 560px) {
  #navbar {
    z-index: 4;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .navbar-hamburger :hover {
    cursor: pointer;
  }

  #navbar.hide-icons {
    justify-content: flex-end;
  }

  #navbar.hide-icons .navbar-icons {
    display: none;
  }
}

.navbar-icons, .navbar-hamburger {
  display: flex;
}

.navbar-icons .icon-wrapper {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  margin-right: 24px;
}

.navbar-icons .icon-wrapper :hover {
  cursor: pointer;
}

.navbar-icons, .navbar-hamburger {
  z-index: 5;
}

.hamburger-menu {
  border-top: 1px solid var(--dark);
  z-index: 2;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  height: auto;
  padding: 32px;
  display: none;
  position: fixed;
  left: 0;
}

.hamburger-menu .projects {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hamburger-menu .projects .category-link-selected {
  color: var(--dark);
  transition: color .24s ease-in-out;
}

.background-blur {
  filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  transition: all .82s ease-in-out;
}

.unblur {
  filter: blur();
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  transition: all .82s ease-in-out;
}

@media only screen and (width <= 560px) {
  .hamburger-menu.visible-menu {
    transition: bottom .7s ease-in-out;
    display: flex;
    bottom: 82px;
    overflow: scroll;
  }

  .hamburger-menu.hidden-menu {
    transition: bottom .7s ease-in-out;
    display: flex;
    bottom: -1000px;
    overflow: hidden;
  }

  .hamburger-menu .links {
    display: flex;
  }
}

@media only screen and (width <= 340px) {
  .hamburger-menu .links li {
    font-size: 12px;
  }

  .hamburger-menu .links {
    margin-top: 10px;
  }

  .hamburger-menu .projects {
    font-size: 24px;
  }

  .hamburger-menu .search-container {
    margin-bottom: 18px;
  }

  .hamburger-menu {
    padding: 24px 32px;
  }

  #navbar {
    padding: 16px;
  }

  .logo-wrapper {
    height: 64px;
  }

  .main {
    top: 87px;
  }
}

.mobile-info {
  text-align: center;
  opacity: 1;
  z-index: 6;
  background-color: #ffffff80;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100% - 91px);
  transition: background-color 1s, opacity 1s, height;
  display: flex;
  position: fixed;
  top: 0;
}

.hide {
  opacity: 0;
  background-color: #fff0;
  height: 0;
  transition: background-color 1s, opacity 1s, height 0s 1s;
}

.mobile-info span {
  font-family: var(--body-font), monospace;
  max-width: 66.66%;
  font-size: 16px;
  line-height: 120%;
}

.hide-info {
  -ms-animation: fadout-hide 1s;
  animation: 1s fadout-hide;
}

@keyframes fadout-hide {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.news-post {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-area: content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "text text text"
                       "image image image";
  min-width: 100%;
  margin-bottom: 128px;
  display: grid;
}

.post-text {
  grid-area: text / 2 / text / text;
}

.post-text h1 {
  max-width: 560px;
  margin-bottom: 8px;
}

.post-images {
  flex-wrap: wrap;
  grid-area: image;
  justify-content: flex-end;
  display: flex;
}

.newsLink {
  background: none;
  border: none;
  outline: none;
}

.newsLink:hover {
  cursor: pointer;
}

.copied {
  font-family: var(--ui-font);
  opacity: 0;
  margin-left: 1em;
  font-size: 12px;
  transition: opacity 1s ease-in;
}

.hide-copied {
  font-family: var(--ui-font);
  opacity: 1;
  margin-left: 1em;
  font-size: 12px;
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
}

.post-images .grid-image img {
  height: auto;
}

article {
  max-width: 620px;
  margin: 32px 0;
}

time {
  font-family: var(--ui-font);
}

#show-more-container {
  justify-content: center;
  width: 100%;
  margin: 24px 0 48px;
  display: flex;
}

#show-more:hover {
  cursor: pointer;
}

@media only screen and (width <= 960px) {
  .news-post {
    padding: 0 24px;
  }

  .post-text {
    grid-column-start: 1;
  }

  .post-images .grid-image {
    width: 100%;
  }
}

@media only screen and (width <= 560px) {
  #show-more-container {
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
    display: flex;
  }

  .news-post:last-of-type {
    margin-bottom: 64px;
  }
}

.logo-wrapper {
  grid-area: header;
  display: flex;
}

.logo {
  text-transform: uppercase;
  letter-spacing: .125em;
  color: var(--dark);
  white-space: nowrap;
  align-self: center;
  font-size: 24px;
  font-weight: bolder;
}

@media only screen and (width <= 1080px) {
  .logo {
    letter-spacing: .1em;
    font-size: 16px;
  }
}

@media only screen and (width <= 560px) {
  .logo {
    letter-spacing: .125em;
    font-size: 24px;
  }

  .logo-wrapper {
    height: var(--row-height);
    background-color: #0000;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.gallery-toggle {
  align-items: center;
  height: 100%;
  display: flex;
}

.layout-icon-container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

#navbar .layout-icon-container {
  margin-top: 0;
}

.icon-wrapper, .navbar-hamburger {
  flex-direction: column;
  align-items: center;
  width: 36px;
  display: flex;
}

.icon-wrapper img, .navbar-hamburger img {
  width: 24px;
  height: 24px;
}

.icon-wrapper span, .navbar-hamburger span {
  font-family: var(--ui-font), sans-serif;
  text-transform: uppercase;
  letter-spacing: .15em;
  margin-top: 8px;
  font-size: 8px;
  line-height: 10px;
}

.gallery-toggle:hover {
  cursor: pointer;
}

.selected-layout-icon {
  opacity: 1;
}

.unselected-layout-icon {
  opacity: .4;
}

#nav-icon {
  -o-transition: .5s ease-in-out;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all .5s ease-in-out;
  position: relative;
  transform: rotate(0);
}

#nav-icon span {
  background: var(--dark);
  opacity: 1;
  -o-transition: .4s ease-in-out;
  border-radius: 2px;
  width: 100%;
  height: 1px;
  margin-top: 0;
  transition: all .4s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  transform: rotate(0);
}

#nav-icon span:first-child {
  transform-origin: 0;
  top: 0;
}

#nav-icon span:nth-child(2) {
  transform-origin: 0;
  top: 10px;
}

#nav-icon span:nth-child(3) {
  transform-origin: 0;
  top: 20px;
}

#nav-icon.open span:first-child {
  top: 2px;
  left: 4px;
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  width: 0%;
}

#nav-icon.open span:nth-child(3) {
  top: 19px;
  left: 4px;
  transform: rotate(-45deg);
}

#cursor {
  will-change: transform;
  pointer-events: none;
  z-index: 10;
  position: fixed;
  top: -34px;
  left: -28px;
}

#cursor img {
  will-change: transform;
  opacity: 0;
  transition: transform .5s cubic-bezier(.6, 1.46, .53, .92), opacity .5s cubic-bezier(.6, 1.46, .53, .92);
}

.image-container > div {
  cursor: none;
}

.image-container * {
  cursor: inherit;
}

@media only screen and (width <= 560px) {
  #cursor {
    display: none;
  }

  .image-container > div {
    cursor: pointer;
  }
}

.topNav {
  grid-area: 1 / 2 / 1 / 2;
  justify-content: space-between;
  width: 100%;
  padding-top: 48px;
  display: flex;
}

.topNav-icons {
  text-transform: uppercase;
  align-items: center;
  height: 100%;
  display: flex;
}

.topNav-icons span:hover {
  cursor: pointer;
}

.topNav-icons .icon-wrapper {
  margin-right: 20px;
}

@media only screen and (width <= 560px) {
  .topNav-icons {
    display: none;
  }
}

#search-message {
  flex-direction: column;
  grid-area: 1 / 2 / 1 / 2;
  justify-content: center;
  max-width: 50%;
  display: flex;
}

#search-message h1 {
  text-align: left;
  margin-left: 0;
  line-height: 100%;
}

@media only screen and (width <= 960px) {
  #search-message {
    display: none;
  }
}

@media only screen and (width <= 560px) {
  #search-message {
    z-index: 1;
    background-color: #fff;
    grid-area: 1 / 1 / 1 / 1;
    width: 100vw;
    max-width: 100%;
    padding: 16px 32px 0;
    display: block;
  }
}

.shop-item {
  flex-direction: column;
  width: 33.33%;
  height: auto;
  margin-bottom: 48px;
  display: flex;
}

.shop-item img {
  background-color: var(--light-gray);
  object-fit: cover;
  width: 100%;
}

.checkout-link, #show-more {
  text-transform: uppercase;
  font-family: var(--ui-font), monospace;
  letter-spacing: .05em;
  border: 1px solid #000;
  border-radius: 4px;
  width: 187px;
  height: 41px;
  font-size: 12px;
}

.checkout-link:hover {
  cursor: pointer;
}

.sold-out .checkout-link:hover {
  cursor: default;
}

.sold-out img:hover {
  cursor: default;
  box-shadow: none;
  transform: none;
}

@media only screen and (width <= 1200px) {
  .shop-item {
    width: 50%;
  }
}

@media only screen and (width <= 560px) {
  .shop-item {
    align-items: center;
    width: 100%;
  }

  .shop-item p {
    text-align: center;
  }
}

.not-found-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.not-found-container h1 {
  padding: 0 12px;
}

.not-found-container figure {
  width: min-content;
  max-height: 50vh;
  margin-top: 24px;
}

.not-found-container figure img {
  object-fit: contain;
  border-radius: 4px;
  width: auto;
  max-height: 100%;
}

.redirect {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  display: flex;
}

.loading-wrapper {
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  height: 30px;
  display: flex;
}

.loading {
  margin-right: 4px;
}

.ellipsis-wrapper {
  width: 30px;
}

.ellipsis {
  text-align: start;
  justify-content: start;
  font-size: 24px;
  display: flex;
}

.ellipsis:after {
  vertical-align: bottom;
  content: "…";
  width: 0;
  animation: .9s steps(4, end) infinite ellipsis;
  display: inline-block;
  overflow: hidden;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
/*# sourceMappingURL=index.e5566a2a.css.map */
